import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux';
import { Container } from "react-bootstrap";

import './App.css';

import ScrollToTop from "./components/ScrollToTop/ScrollTotop";
import ScrollToTopButton from "./components/ScrollToTop/ScrollToTopButton";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Accueil from "./screens/Accueil/Accueil";
import Collaborateurs from "./screens/Collaborateurs/Collaborateurs";
import Valeurs from "./screens/Valeurs/Valeurs";
import Partenaires from "./screens/Partenaires/Partenaires";
import OffresEmplois from "./screens/OffresEmplois/OffresEmplois";
import Contact from "./screens/Contact/Contact";
import Connexion from "./screens/Connexion/Connexion";
import Administration from "./screens/Administration/Administration";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import {Propos} from "./screens/Propos/Propos";
import Projets from "./screens/Projets/Projets"
import { NousRejoindre } from "./screens/NousRejoindre/NousRejoindre";
import { CandidatureForm } from "./screens/CandidatureForm/CandidatureForm";
import { OffreEmploiDetail } from "./screens/OffreEmploiDetail/OffreEmploiDetail";

import axios from 'axios';


function App() {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  return (
    <Router>
      <ScrollToTop />
      <main>
        <Header />
        {userInfo ?
          <Routes>

            <Route path="/" element={<Accueil />} />
              <Route path="/about" element={<Propos/>} />
              <Route path="/projets" element={<Projets />} />
            <Route path="/accueil" element={<Accueil />} />
            <Route path="/join" element={<NousRejoindre />} />
            <Route path="/candidate" element={<CandidatureForm />} />
            <Route path="/offres-detail" element={<OffreEmploiDetail />} />
            <Route path="/collaborateurs" element={<Collaborateurs />} />
            <Route path="/nos-valeurs" element={<Valeurs />} />
            <Route path="/nos-partenaires" element={<Partenaires />} />

            <Route path="/offres-emploi" element={<OffresEmplois />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/administration" element={<Administration />} />

            <Route path="*" element={<PageNotFound />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
          :
          <Container className="children"> {/* Dans SideBar */}
            <Routes>
              {/* Routes Administrateur */}
              <Route path="/" element={<Accueil />} />
              <Route path="/about" element={<Propos/>} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/join" element={<NousRejoindre />} />
              <Route path="/candidate" element={<CandidatureForm />} />
              <Route path="/offres-detail" element={<OffreEmploiDetail />} />
              <Route path="/collaborateurs" element={<Collaborateurs />} />
              <Route path="/nos-valeurs" element={<Valeurs />} />
              <Route path="/nos-partenaires" element={<Partenaires />} />

              <Route path="/offres-emploi" element={<OffresEmplois />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/connexion" element={<Connexion />} />
              <Route path="/projets" element={<Projets />} />
              {/* Routes Visiteur */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        }

        <Footer />
        <ScrollToTopButton />
      </main>
    </Router>
  );
}

export default App;
