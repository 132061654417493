import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { logout } from '../../redux/actions/userActions';
import { ActionButton } from "../Button/ActionButton/ActionButton";

import logo from '../../assets/coode-nt-full-1.png';
import './Header.css';


function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State pour gérer l'ouverture/fermeture du menu
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const logoutHandler = () => {
        dispatch(logout());
            setTimeout(() => {
        navigate('/accueil');
    }, 0); // Petit délai pour laisser le temps au dispatch de terminer
        setIsMenuOpen(false); // Fermer le menu quand on se déconnecte
    };

    // Fonction pour gérer le clic sur le menu burger
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Fonction pour fermer le menu lorsque l'utilisateur clique sur un lien
    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };

    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'actif' : '';
    };

    return (
        <>
            <header className="header">
                <Link to="/accueil" onClick={handleLinkClick}>
                    <img className="coode-nt-full" alt="Coode nt full" src={logo} />
                </Link>

                {/* Bouton menu burger, visible uniquement sur mobile */}
                <div className={`menu-btn ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="menu-btn__burger"></div>
                </div>

                <nav className={`auto-layout ${isMenuOpen ? 'open' : ''}`}>
                    <Link className={`text-wrapper ${isActive('/accueil')}`} to="/accueil" onClick={handleLinkClick}>
                        Accueil
                    </Link>
                    <Link className={`div ${isActive('/about')}`} to="/about" onClick={handleLinkClick}>
                        À Propos
                    </Link>
                    <Link className={`div ${isActive('/projets')}`} to="/projets" onClick={handleLinkClick}>
                        Nos Projets
                    </Link>
                    <Link className={`div ${isActive('/join')}`} to="/join" onClick={handleLinkClick}>
                        Nous Rejoindre
                    </Link>
                    <Link className={isActive('/contact')} to="/contact" onClick={handleLinkClick}>
                        <ActionButton className="header-1" divClassName="button-instance" style="filled" text="Contact" />
                    </Link>
                    {userInfo && userInfo.isAdmin && (
                        <Link className={`div ${isActive('/administration')}`} to="/administration" onClick={handleLinkClick}>
                            Administration
                        </Link>
                    )}
                    {userInfo && userInfo.isAdmin && (
                        <Link onClick={logoutHandler} className={`div ${isActive('/contact')}`}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> {/* Icône de déconnexion */}
                        </Link>
                    )}
                </nav>
            </header>
        </>
    );
}

export default Header;
