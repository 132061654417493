import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './ScrollToTopButton.css'; // Tu peux ajouter du style ici


const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Fonction pour faire défiler jusqu'en haut
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',  // Animation fluide
    });
  };

  // Contrôler la visibilité du bouton en fonction du défilement de l'utilisateur
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {  // Si l'utilisateur a scrollé plus de 300px
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    // Nettoyage lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <ArrowUpwardIcon style={{ fontSize: 24 }}/>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
